import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/phorkit/phorkit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Flex } from 'components/Flex';
import { PageTitle } from 'docs/helpers/PageTitle';
import { Triangle } from '../index';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageTitle src="components/Triangle" title="Triangle" mdxType="PageTitle" />
    <Playground __position={1} __code={'<Flex justifyContent=\"space-around\">\n  <Triangle color=\"#0060ce\" position=\"top-left\" size={30} title=\"top-left\" />\n  <Triangle color=\"#0060ce\" position=\"top\" size={30} title=\"top\" />\n  <Triangle\n    color=\"#0060ce\"\n    position=\"top-right\"\n    size={30}\n    title=\"top-right\"\n  />\n</Flex>\n<Flex justifyContent=\"space-around\">\n  <Triangle color=\"#0060ce\" position=\"left\" size={30} title=\"left\" />\n  <Triangle color=\"#0060ce\" position=\"right\" size={30} title=\"right\" />\n</Flex>\n<Flex justifyContent=\"space-around\">\n  <Triangle\n    color=\"#0060ce\"\n    position=\"bottom-left\"\n    size={30}\n    title=\"bottom-left\"\n  />\n  <Triangle color=\"#0060ce\" position=\"bottom\" size={30} title=\"bottom\" />\n  <Triangle\n    color=\"#0060ce\"\n    position=\"bottom-right\"\n    size={30}\n    title=\"bottom-right\"\n  />\n</Flex>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      PageTitle,
      Triangle,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Flex justifyContent="space-around" mdxType="Flex">
    <Triangle color="#0060ce" position="top-left" size={30} title="top-left" mdxType="Triangle" />
    <Triangle color="#0060ce" position="top" size={30} title="top" mdxType="Triangle" />
    <Triangle color="#0060ce" position="top-right" size={30} title="top-right" mdxType="Triangle" />
  </Flex>
  <Flex justifyContent="space-around" mdxType="Flex">
    <Triangle color="#0060ce" position="left" size={30} title="left" mdxType="Triangle" />
    <Triangle color="#0060ce" position="right" size={30} title="right" mdxType="Triangle" />
  </Flex>
  <Flex justifyContent="space-around" mdxType="Flex">
    <Triangle color="#0060ce" position="bottom-left" size={30} title="bottom-left" mdxType="Triangle" />
    <Triangle color="#0060ce" position="bottom" size={30} title="bottom" mdxType="Triangle" />
    <Triangle color="#0060ce" position="bottom-right" size={30} title="bottom-right" mdxType="Triangle" />
  </Flex>
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`[`}{`props`}{`]`}</h2>
    <Props of={Triangle} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      